import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { colorModeContext, tokens } from "../Theme";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import "./Topbar.css";

const NavItem = ({ href, title, selected, setSelected, closeDrawer }) => {
  return (
    <NavLink
      className={
        selected.toLowerCase() === title.toLowerCase()
          ? "nav-link nav-link-active"
          : "nav-link"
      }
      onClick={() => {
        setSelected(title.toLowerCase());
        closeDrawer();
      }}
      to={href}
    >
      {title}
    </NavLink>
  );
};

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(colorModeContext);
  const [selected, setSelected] = useState("home");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (e) => {
    const pos = window.scrollY;
    setScrollPosition(pos);
  };

  useEffect(() => {
    const pathname = window.location.pathname.substring(
      1,
      window.location.pathname.length
    );
    setSelected(pathname);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className="custom-nav"
      style={{
        backgroundColor:
          scrollPosition <= 60 ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.9)",
        top: scrollPosition > 60 ? 0 : 60,
        transition: "top 0.2s ease-in-out",
      }}
    >
      <div className="custom-nav-logo">
        <img src="/images/logo.svg" />
      </div>
      <ul className={drawerOpen ? "navbar-active" : ""}>
        <li>
          <NavItem
            title={"Home"}
            href={"/"}
            selected={selected}
            setSelected={setSelected}
            closeDrawer={() => setDrawerOpen(false)}
          />
        </li>
        <li>
          <NavItem
            title={"About"}
            href={"about"}
            selected={selected}
            setSelected={setSelected}
            closeDrawer={() => setDrawerOpen(false)}
          />
        </li>
        <li>
          <NavItem
            title={"Services"}
            href={"/services"}
            selected={selected}
            setSelected={setSelected}
            closeDrawer={() => setDrawerOpen(false)}
          />
        </li>
        <li>
          <NavItem
            title={"Contact"}
            href={"/contacts"}
            selected={selected}
            setSelected={setSelected}
            closeDrawer={() => setDrawerOpen(false)}
          />
        </li>
        <li>
          <NavItem
            title={"Booking"}
            href={"/bookings"}
            selected={selected}
            setSelected={setSelected}
            closeDrawer={() => setDrawerOpen(false)}
          />
        </li>
      </ul>
      <div id="mobile" onClick={() => setDrawerOpen(!drawerOpen)}>
        {drawerOpen ? (
          <CloseIcon style={{ fontSize: "24px" }} />
        ) : (
          <MenuIcon style={{ fontSize: "24px" }} />
        )}
      </div>
    </nav>
  );
};

export default Topbar;
