import InfoCard from "../../components/card";
import "./index.css";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-header">
        <h1>Who We Are</h1>
        <p>
          Phoenix Accessible Transportation is a non-emergency Medical
          Transportation company that provides ambulatory and wheelchair
          transportation to individuals throughout the greater Phoenix area and
          adjacent areas.
        </p>
      </div>
      <div className="about-info">
        <div className="about-info-text">
          <p>
            Phoenix Accessible Transportation was birthed after two caregivers,
            now owners experienced the hands of how people with limited mobility
            relied on non-emergency medical transportation for their medical
            appointments and ended up missing those appointments because the
            transportation providers showed up late or did not show up at all
            without even communicating. Therefore, we started this business to
            support people with limited mobility to get to Doctor's
            appointments, Dialysis sessions, and home from the hospital after
            discharge, among other places with the believe that we will make a
            difference in the lives of others.
          </p>
          <br />
          <p>
            Our goal is to Make Accessible Transportation a Reality for All!
          </p>
        </div>
        <img src="/images/driver.jpg" className="about-info-img" />
      </div>
      <div className="about-driver">
        <img src="/images/van.jpg" className="about-driver-img" />
        <div>
          <h4>Each Driver Must:</h4>
          <ul>
            <li>
              Pass all required federal, state, and local background checks
            </li>
            <li>Undergo a Department of Justice Background Check</li>
            <li>Undergo a National Sex Offenders Registry Screening</li>
            <li>
              Pass employment drug and alcohol screenings as allowed and
              required by law
            </li>
            <li>
              Take a sexual harassment training Take defensive driving training
              and pass
            </li>
          </ul>
        </div>
      </div>
      <div className="about-cards">
        <InfoCard
          className={"about-card"}
          title={"Mission"}
          content={
            "At Phoenix Accessible Transportation, our goal is to ensure that our clients have access to the above industry services that they need, including reliable accessible transportation. Our team of dedicated professionals goes above and beyond their routine work to foster a personal connection with each client to ensure we offer personalized packages to meet specific client needs."
          }
        />
        <InfoCard
          className={"about-card"}
          title={"Vision"}
          content={
            "To empower our clients by giving them excellent service using top-notch tools and the best customer experience "
          }
        />
        <InfoCard
          className={"about-card"}
          title={"Purpose"}
          content={
            "To ensure our clients have the best service with regards to transportation, and giving care to them in the best way possible."
          }
        />
      </div>
    </div>
  );
};

export default About;
