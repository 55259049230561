import "./iconcard.css";

const IconServiceCard = ({ icon, title, content, className }) => {
  return (
    <div className={`icon-service-container ${className}`}>
      <div className="service-icon">{icon}</div>
      <div className="title">{title}</div>
      <div className="content">{content}</div>
    </div>
  );
};

export default IconServiceCard;
