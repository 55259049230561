import { Link } from "react-router-dom";
import "./index.css";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IconButton } from "@mui/material";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="sitemap-header">Site Map</div>
      <div className="sitemap">
        <Link className="sitemap-link">Home</Link>
        <Link className="sitemap-link">About</Link>
        <Link className="sitemap-link">Services</Link>
        <Link className="sitemap-link">Contacts</Link>
      </div>
      <div className="sitemap-header">Our Socials</div>
      <div className="socials">
        <IconButton
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=61555613077722",
              "blank"
            )
          }
        >
          <FacebookOutlinedIcon
            style={{ width: "30px", height: "30px", color: "#4267B2" }}
          />
        </IconButton>
        <IconButton
          onClick={() =>
            window.open(
              "https://www.instagram.com/phoenixaccessibletrans/",
              "blank"
            )
          }
        >
          <InstagramIcon
            style={{
              width: "30px",
              height: "30px",
              color: "#C13584",
            }}
          />
        </IconButton>
      </div>
      <div className="footer-bottom">
        &copy;&nbsp;G-Pride Tracesoft Group Ltd {new Date().getUTCFullYear()}.
        All rights reserved
      </div>
    </div>
  );
};

export default Footer;
