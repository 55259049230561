import IconServiceCard from "../../components/card/IconServiceCard";
import ServiceCard from "../../components/card/ServiceCard";
import "./index.css";

import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import WheelchairPickupIcon from "@mui/icons-material/WheelchairPickup";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";

const Services = () => {
  return (
    <div className="services-container">
      <div className="services-header">
        <h1>We are Here To Help</h1>
        <p>
          We at Phoenix Accessible Transportation commit to continually offering
          above-industry-standard accessible transportation services that exceed
          our customers' expectations. Our dedicated professionals go above and
          beyond their routine work to foster a personal connection with each
          client to ensure we offer personalized packages to meet specific
          client needs with professionalism, integrity, respect, and dignity.
        </p>
      </div>
      <h4>Our Services Include</h4>
      <p>Transportation To and From</p>
      <div className="services-list">
        <ServiceCard
          className={"serviceCard"}
          title="Medical Appointments"
          image={"/images/waitingroom.jpeg"}
        />
        <ServiceCard
          className={"serviceCard"}
          title="Dialysis Centers"
          image={"/images/dialysis.jpeg"}
        />
        <ServiceCard
          className={"serviceCard"}
          title="Hospital Admissions and Discharges"
          image={"/images/hospital.jpeg"}
        />
      </div>
      <div className="services-list">
        <ServiceCard
          className={"serviceCard"}
          title="Adult Care Facilities"
          image={"/images/serene.jpeg"}
        />
        <ServiceCard
          className={"serviceCard"}
          title="Adult Day Care Centers"
          image={"/images/daycare.jpeg"}
        />
        <ServiceCard
          className={"serviceCard"}
          title={"Medication Pickup"}
          image={"/images/medicine.jpg"}
        />
      </div>
      <div className="services-list">
        <ServiceCard
          className={"serviceCard"}
          title="Chemotherapy"
          image={"/images/chemo.jpeg"}
        />
        <ServiceCard
          className={"serviceCard"}
          title="Outpatient Physical/Occupational/Speech therapy"
          image={"/images/doctors.jpeg"}
        />
      </div>
      <div className="services-list">
        <IconServiceCard
          className={"service-icon-card"}
          icon={
            <WheelchairPickupIcon style={{ width: "100px", height: "100px" }} />
          }
          title={"Wheelchair Service"}
          content={
            " Our drivers will provide door-to-door service, always keeping patients supervised."
          }
        />
        <IconServiceCard
          className={"service-icon-card"}
          icon={
            <LocalPharmacyIcon style={{ width: "100px", height: "100px" }} />
          }
          title={"Local Hospital Transport"}
          content={
            "Need assistance to get to hospital? Worry no more. Our qualified staff will aid you to get into our vans and take you to hospital"
          }
        />
        <IconServiceCard
          className={"service-icon-card"}
          icon={
            <MonitorWeightIcon style={{ width: "100px", height: "100px" }} />
          }
          title="Bariatric"
          content="Our vehicles can handle patients that weigh upto 250lbs"
        />
      </div>
    </div>
  );
};

export default Services;
