import { useCallback, useEffect, useRef, useState } from "react";
import "./booking.css";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { ArrowForward, Directions } from "@mui/icons-material";
import emailjs from "@emailjs/browser";

const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  },
  DEFAULT_CENTER: { lat: 33.241700872009595, lng: -111.59485494619659 },
  DEFAULT_ZOOM: 4,
  MARKER_SIZE: {
    SMALL: 25,
    LARGE: 45,
  },
  PIXEL_OFFSET: {
    X: 0,
    Y: 20,
  },
  POLYLINE_OPTIONS: {
    DASHED: {
      geodesic: true,
      strokeOpacity: 0,
      strokeWeight: 2,
      strokeColor: "#0000ff",
      icons: [
        {
          icon: {
            path: "M 0,0 0,1",
            strokeOpacity: 1,
            strokeWeight: 2,
            scale: 3,
          },
          offset: "0",
          repeat: "10px",
        },
      ],
    },
    REGULAR: {
      geodesic: true,
      strokeOpacity: 1,
      strokeWeight: 2,
      strokeColor: "#ff0000",
    },
  },
};

const places = ["places", "routes"];

const Booking = () => {
  const [map, setMap] = useState(null);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [tripType, setTripType] = useState("wheelchair");
  const [pickupDate, setPickupDate] = useState(dayjs(new Date().toISOString()));
  const [returnDate, setReturnDate] = useState(dayjs(new Date().toISOString()));
  const [roundTrip, setRoundTrip] = useState(false);
  const [fsuggestions, setFSuggestions] = useState("");
  const [tsuggestions, setTSuggestions] = useState("");
  const [weight, setWeight] = useState(0);
  const [direcitons, setDirections] = useState();
  const [directionsService, setDirectionService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();
  const [tripTotal, setTripTotal] = useState(0);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: places,
  });

  const holidays = [];

  useEffect(() => {
    if (isLoaded) {
      setDirectionService(new window.google.maps.DirectionsService());
      setDirectionsRenderer(
        new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
        })
      );
    }
  }, [isLoaded]);

  useEffect(() => {
    if (map) {
      if (directionsRenderer) {
        directionsRenderer.setMap(map);
      }
    }
  }, [map, directionsRenderer]);

  const onAutocompleteLoad = (type, autocomplete) => {
    if (type === "from") setFSuggestions(autocomplete);
    else setTSuggestions(autocomplete);
  };

  const onMapLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(
      MAP_SETTINGS.DEFAULT_CENTER
    );
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onMapUnmount = useCallback(function callback(map) {
    setMap(null);
    if (directionsRenderer) {
      directionsRenderer.setMap(null);
    }
  }, []);

  const onPlaceChanged = (type) => {
    let place;
    if (type === "from") {
      if (fsuggestions != null && fsuggestions !== "") {
        //variable to store the result
        place = fsuggestions.getPlace();
      } else {
        toast.info(`Start typing to search an address`);
      }
    } else {
      if (tsuggestions != null && tsuggestions !== "") {
        //variable to store the result
        place = tsuggestions.getPlace();
      } else {
        toast.info(`Start typing to search an address`);
      }
    }

    if (place) {
      if (place.geometry) {
        const address = {
          id: place.place_id,
          name: place.name,
          formatted_address: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        if (type === "from") {
          setFrom(address);
        } else {
          setTo(address);
        }
      } else {
        toast.error(
          "The selected place does not have sufficient information to map"
        );
      }
    } else {
      toast.error(
        "Could not find an address matching the name. Please try again"
      );
    }
  };

  useEffect(() => {
    if (from && to) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(from.lat, from.lng));
      bounds.extend(new window.google.maps.LatLng(to.lat, to.lng));
      map.fitBounds(bounds);

      var request = {
        origin: from.name,
        destination: to.name,
        travelMode: "DRIVING",
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      };
      directionsService.route(request, function (result, status) {
        if (status == "OK") {
          console.log(result);
          directionsRenderer.setDirections(result);
          getDirectionsDetails(result);
        } else {
          toast.error("Error fetching direcitons");
        }
      });
    }
  }, [from, to]);

  const getDirectionsDetails = (result) => {
    if (result.routes.length > 0) {
      const route = result.routes[0];
      const directios = {
        e_points: route.overview_polyline.points,
        distance_text: route.legs[0].distance.text,
        distance_value: route.legs[0].distance.value,
        duration_text: route.legs[0].duration.text,
        duration_value: route.legs[0].duration.value,
      };
      setDirections(directios);
    } else {
      toast.error("No routes found between selected locations");
    }
  };

  const calculateTripTotal = () => {
    let basecost;
    //deal with after hours + weekends
    if (dayjs(pickupDate).day() === 0 || dayjs(pickupDate).day() === 6) {
      basecost = 70;
    } else {
      if (dayjs(pickupDate).hour() < 8 || dayjs(pickupDate).hour() > 16) {
        basecost = 70;
      } else {
        basecost = 50;
      }
    }
    //deal with distances > 250
    const dist = direcitons.distance_value / 1609;
    let distCost;
    if (dist <= 250)
      if (weight > 500) distCost = dist * 5;
      else distCost = dist * 3.5;
    else distCost = dist * 5;
    //combine costs
    let total = basecost + distCost;
    if (roundTrip) {
      total *= 2;
    }
    setTripTotal(total);
  };

  const submitData = () => {
    setLoading(true);
    emailjs.init({
      publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      // Do not allow headless browsers
      blockHeadless: true,
    });

    const mailData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      from: from.formatted_address,
      to: to.formatted_address,
      trip_type: tripType,
      pickup_date: new Date(pickupDate).toLocaleString(),
      return_date: roundTrip ? new Date(returnDate).toLocaleString() : "",
      round_trip: roundTrip,
      weight: weight,
      total_cost: tripTotal,
      comment: comment,
      distance_total: roundTrip
        ? direcitons
          ? ((direcitons.distance_value / 1609) * 2).toFixed(2)
          : "0"
        : direcitons
        ? (direcitons?.distance_value / 1609).toFixed(2)
        : "0",
    };

    emailjs.send("service_phoenix2024", "template_pnyid3o", mailData).then(
      (response) => {
        toast.success("Your Booking request has been sent successfully");
        setLoading(false);
      },
      (error) => {
        toast.error(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (direcitons) {
      calculateTripTotal();
    }
  }, [direcitons, weight, from, roundTrip]);

  return (
    <div className="booking-container">
      <div className="booking-header">
        <h1>Bookings</h1>
        <p>Make your online booking below</p>
      </div>
      <div className="booking-form-container">
        <div className="booking-form">
          <div className="booking-form-heading">
            Place your Trip Request Here
          </div>
          {isLoaded && (
            <>
              <Autocomplete
                className="booking-form-input"
                onPlaceChanged={() => onPlaceChanged("from")}
                onLoad={(autocomplete) =>
                  onAutocompleteLoad("from", autocomplete)
                }
                restrictions={{ country: ["US"] }}
              >
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Pickup Address*"
                />
              </Autocomplete>

              <Autocomplete
                className="booking-form-input"
                restrictions={{ country: ["US"] }}
                onPlaceChanged={() => onPlaceChanged("to")}
                onLoad={(autocomplete) =>
                  onAutocompleteLoad("to", autocomplete)
                }
              >
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Dropoff Address*"
                />
              </Autocomplete>
            </>
          )}

          <div className="booking-form-section booking-row">
            <TextField
              required
              style={{ marginRight: "10px" }}
              fullWidth
              id="first-name"
              type="text"
              label="First Name*"
              variant="outlined"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              required
              style={{ marginLeft: "10px" }}
              fullWidth
              id="last-name"
              type="text"
              label="Last Name*"
              variant="outlined"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="booking-form-section booking-row">
            <TextField
              required
              style={{ marginRight: "10px" }}
              fullWidth
              id="email"
              type="email"
              label="Email Address*"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              required
              style={{ marginLeft: "10px" }}
              fullWidth
              id="phone"
              type="tel"
              label="Phone Number*"
              variant="outlined"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className="booking-form-section booking-row">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Trip Type*</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tripType}
                label="Trip Type*"
                onChange={(e) => setTripType(e.target.value)}
              >
                <MenuItem value={"discharge"}>Hospital Discharge</MenuItem>
                <MenuItem value={"bariatric"}>Bariatric</MenuItem>
                <MenuItem value={"wheelchair"}>Wheelchair</MenuItem>
                <MenuItem value={"ambulatory"}>Ambulatory</MenuItem>
              </Select>
            </FormControl>
            {tripType === "bariatric" && (
              <TextField
                fullWidth
                style={{ marginLeft: "10px" }}
                id="weight-spec"
                type="number"
                label="Weight (lbs)*"
                variant="outlined"
                onChange={(e) => setWeight(parseInt(e.target.value))}
              />
            )}
          </div>
          <div className="booking-form-section booking-row">
            <FormControlLabel
              value={"Round Trip?"}
              label={"RoundTrip"}
              labelPlacement="start"
              control={
                <Switch onChange={(e) => setRoundTrip(e.target.checked)} />
              }
              style={{ marginRight: "10px" }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                label="Pickup Date*"
                value={pickupDate}
                onChange={(newValue) => setPickupDate(newValue)}
                className="booking-form-input"
                sx={{
                  marginRight: roundTrip ? "5px" : "0",
                }}
              />
            </LocalizationProvider>
            {roundTrip && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label="Dropoff Date*"
                  value={returnDate}
                  onChange={(newValue) => setReturnDate(newValue)}
                  className="booking-form-input"
                  sx={{ marginLeft: "5px" }}
                />
              </LocalizationProvider>
            )}
          </div>
          <div className="booking-form-section booking-row">
            <TextField
              fullWidth
              multiline
              rows={7}
              placeholder="Any Additional comments or information?"
              id="comments"
              type="text"
              label="Additional Information"
              variant="outlined"
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className="booking-form-info">
            <div className="booking-form-distance">
              <div className="cost-section">{`${
                roundTrip
                  ? direcitons
                    ? ((direcitons.distance_value / 1609) * 2).toFixed(2)
                    : "0"
                  : direcitons
                  ? (direcitons?.distance_value / 1609).toFixed(2)
                  : "0"
              } mi`}</div>
              <div className="cost-description">Estimated Trip Distance*</div>
            </div>
            <div className="booking-form-distance">
              <div className="cost-section">{`$${tripTotal.toFixed(2)}`}</div>
              <div className="cost-description">Estimated Trip Cost*</div>
            </div>
          </div>
          <Button
            variant="contained"
            color="success"
            className="booking-form-action"
            disabled={loading}
            onClick={() => submitData()}
          >
            Book Ride
          </Button>
        </div>
        <div className="booking-map" id="googlemap">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{
                borderRadius: "12px",
                width: "100%",
                height: "100%",
              }}
              center={MAP_SETTINGS.DEFAULT_CENTER}
              zoom={MAP_SETTINGS.DEFAULT_ZOOM}
              onLoad={onMapLoad}
              onUnmount={onMapUnmount}
            >
              {/* Child components, such as markers, info windows, etc. */}
              {from && (
                <Marker
                  key={"from-address"}
                  position={{ lat: from.lat, lng: from.lng }}
                  icon={{
                    url: "/images/personicon.png",
                    scaledSize: new window.google.maps.Size(
                      MAP_SETTINGS.MARKER_SIZE.LARGE,
                      MAP_SETTINGS.MARKER_SIZE.LARGE
                    ),
                  }}
                />
              )}
              {to && (
                <Marker
                  key={"to-address"}
                  position={{ lat: to.lat, lng: to.lng }}
                  icon={{
                    url: "/images/homeicon.png",
                    scaledSize: new window.google.maps.Size(
                      MAP_SETTINGS.MARKER_SIZE.LARGE,
                      MAP_SETTINGS.MARKER_SIZE.LARGE
                    ),
                  }}
                />
              )}
            </GoogleMap>
          )}
        </div>
      </div>
    </div>
  );
};
export default Booking;
