import "./Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
import IconServiceCard from "../../components/card/IconServiceCard";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ServiceCard from "../../components/card/ServiceCard";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

const Home = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("only screen and (min-width : 768px)");
  return (
    <div className="home-container">
      <img
        src="/images/sereneenv.jpeg"
        style={{
          height: "30vh",
          width: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      <Box
        width={"100%"}
        height={"50vh"}
        display={"flex"}
        flexDirection={isNonMobile ? undefined : "column"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        padding={"2px"}
      >
        <Box
          display={"flex"}
          flexDirection={isNonMobile ? "column" : "row"}
          height={"40vh"}
          width={isNonMobile ? "20vw" : "100%"}
          justifyContent={"space-evenly"}
          sx={{
            marginTop: isNonMobile ? undefined : "30px",
          }}
        >
          <Typography
            variant="h1"
            style={{
              font: "Nunito, sans-serif",
              fontWeight: "800",
              fontSize: isNonMobile ? undefined : "16pt",
              textAlign: isNonMobile ? undefined : "center",
            }}
          >
            Ride with us and save Money
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"200px"}
            height={isNonMobile ? undefined : "50px"}
            padding={"20px 20px"}
            sx={{
              background: "rgb(92, 92, 234)",
              borderRadius: "50px",
              color: "white",
              fontSize: "16pt",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={() => navigate("/bookings")}
          >
            <Typography
              variant="h4"
              style={{
                fontSize: "14pt",
                textDecoration: "none",
                color: "white",
                fontWeight: "600",
              }}
            >
              Start Here
            </Typography>
            <ArrowForward />
          </Box>
        </Box>
        <Box display={"flex"}>
          <Box
            width={isNonMobile ? "350px" : "150px"}
            height={isNonMobile ? "350px" : "150px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              overflow: "hidden",
              background: "#000",
            }}
          >
            <img
              src="/images/vanback2.webp"
              alt="Carrier van"
              style={{ width: "98%", height: "98%", objectFit: "cover" }}
            />
          </Box>
          <Box
            width={isNonMobile ? "350px" : "150px"}
            height={isNonMobile ? "350px" : "150px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              overflow: "hidden",
              background: "#000",
              transform: "rotate(320deg)",
            }}
          >
            <img
              src="/images/vanside.webp"
              alt="Carrier van"
              style={{ width: "98%", height: "98%", objectFit: "cover" }}
            />
          </Box>
        </Box>
      </Box>
      <div className="home-booknow">
        <Link to="/bookings" className="button">
          Book Now
        </Link>
      </div>
      <div className="home-company">
        <IconServiceCard
          className="home-icon-card"
          title={"Mission"}
          content={
            "To ensure that our clients have access to the above industry services that they need, including reliable accessible transportation."
          }
          icon={
            <RocketLaunchIcon style={{ width: "100px", height: "100px" }} />
          }
        />

        <IconServiceCard
          className="home-icon-card"
          title={"Vision"}
          content={
            "To empower our clients by giving them excellent service using top-notch tools and the best customer experience"
          }
          icon={<ShowChartIcon style={{ width: "100px", height: "100px" }} />}
        />
        <IconServiceCard
          className="home-icon-card"
          title={"Our Commitment"}
          content={
            "To ensure our clients have the best service with regards to transportation, and giving care to them in the best way possible."
          }
          icon={<HandshakeIcon style={{ width: "100px", height: "100px" }} />}
        />
      </div>
      <div className="home-services">
        <h4>Our Services</h4>
        <div className="service-list">
          <ServiceCard
            className={"service-card"}
            title={"Hospital Discharges"}
            image={"/images/doctors.jpeg"}
          />
          <ServiceCard
            className={"service-card"}
            title={"Adult Daycare Centers"}
            image={"/images/daycare.jpeg"}
          />
          <ServiceCard
            className={"service-card"}
            title={"Pharmacies (Medication Pickup)"}
            image={"/images/medicine.jpg"}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
