import "./ServiceCard.css";

const ServiceCard = ({ title, image, className }) => {
  return (
    <div
      className={`servicecard-container ${className}`}
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${image})`,
      }}
    >
      <div className="servicecard-title" style={{}}>
        {title}
      </div>
    </div>
  );
};

export default ServiceCard;
