import { ErrorMessage, Field, Formik } from "formik";
import "./index.css";
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { EmailOutlined, PhoneAndroidOutlined } from "@mui/icons-material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";

const Contacts = () => {
  const submitForm = (values) => {};
  return (
    <div className="contacts-container">
      <div className="contacts-header">
        <h1>Reach out to us</h1>
        <p>
          We are ready to offer you assistance to the best of our capabilities
        </p>
      </div>
      <div className="contacts-body">
        <div className="contacts-form">
          <h4>Write To Us</h4>
          <Formik
            initialValues={{ username: "", email: "", message: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.username) {
                errors.username = "This field is required";
              }
              if (!values.email) {
                errors.email = "This field is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address provided";
              }
              if (!values.message) {
                errors.message = "Thie field is required";
              }
              return errors;
            }}
            onSubmit={submitForm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl className="form-control">
                  <TextField
                    label="Username"
                    value={values.username}
                    onChange={(e) => setFieldValue("username", e.target.value)}
                    onBlur={handleBlur}
                    error={!!touched && !!errors.username}
                    helperText={touched.username && errors.username}
                  />
                </FormControl>
                <FormControl className="form-control">
                  <TextField
                    aria-describedby="email-helper"
                    label="Email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    onBlur={handleBlur}
                    error={!!touched && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                  <FormHelperText id="email-helper">
                    We'll never share your email.
                  </FormHelperText>
                </FormControl>
                <FormControl className="form-control">
                  <TextField
                    multiline
                    rows={5}
                    label="Message"
                    value={values.message}
                    onChange={(e) => setFieldValue("message", e.target.value)}
                    onBlur={handleBlur}
                    error={!!touched && !!errors.message}
                    helperText={touched.message && errors.message}
                  />
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        </div>
        <div className="contacts-divider"></div>
        <div className="contacts-contacts">
          <h4>Reach out to us</h4>
          <div
            className="contacts-item"
            onClick={() =>
              (window.location.href = "mailto:phoenixaccessible@gmail.com")
            }
          >
            <EmailOutlined
              style={{
                width: "30px",
                height: "30px",
                color: "rgb(181, 178, 178)",
              }}
            />
            <p>Phoenixaccessible@gmail.com</p>
          </div>
          <div
            className="contacts-item"
            onClick={() => (window.location.href = "tel:16023129993")}
          >
            <PhoneAndroidOutlined
              style={{
                width: "30px",
                height: "30px",
                color: "rgb(181, 178, 178)",
              }}
            />
            <p>+1 (602) 312-9993</p>
          </div>
          <div
            className="contacts-item address"
            onClick={() =>
              window.open("https://maps.app.goo.gl/Hv94jLbwrd5cevxcA", "_blank")
            }
          >
            <HomeWorkIcon
              style={{
                width: "30px",
                height: "30px",
                color: "rgb(181, 178, 178)",
              }}
            />
            <div className="p">
              <p>22647,</p>
              <p>E Parkside,</p>
              <p>Dr Queen Creek, Az</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
