import { Box, Typography } from "@mui/material";

const Banner = () => {
  return (
    <Box
      width={"100%"}
      height={"60px"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ background: "#FDDE6C" }}
    >
      <Typography
        variant="h6"
        style={{
          fontStyle: "italic",
          fontSize: "16pt",
          color: "black",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Early Bird Discount: 10% off for our first riders!
      </Typography>
    </Box>
  );
};

export default Banner;
