import "./index.css";

const InfoCard = ({ title, content, className }) => {
  return (
    <div className={`card-container ${className}`}>
      <div className="card-border">
        <div className="card-title">{title}</div>
        <div className="card-content">{content}</div>
      </div>
    </div>
  );
};

export default InfoCard;
